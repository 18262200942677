import { ApiEndpointBuilder } from "@/services/api/types";
import { DataResponse } from "@/types/reponse-data";
import { AdvancedFilter } from "@/types/search-request";
import { EnterprisesMap } from "@ilan-types/store/profile";

const searchEnterprisesMapEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<EnterprisesMap>, SearchRequest>({
    query: request => ({
      url: "/enterprises/search/map",
      method: "POST",
      body: request,
    }),
    serializeQueryArgs: ({ endpointName }) => {
      return endpointName;
    },

    async onQueryStarted(_arg, api) {
      try {
        const { data } = await api.queryFulfilled;
        if (data) {
          api.updateCachedData(draft => {
            return draft;
          });
        }
      } catch (err) {
        console.error(err);
      }
    },

    merge: (currentCache, newData) => {
      const cachePage = currentCache?.currentPage || 0;

      if (currentCache.data && newData?.currentPage && cachePage < +newData?.currentPage) {
        return {
          ...newData,
          data: [...currentCache.data, ...newData.data],
        };
      } else {
        return newData;
      }
    },

    forceRefetch({ currentArg, previousArg }) {
      return currentArg !== previousArg;
    },

    providesTags: ["EnterpriseAsMap"],
  });
};

export default searchEnterprisesMapEndpoint;

export interface SearchRequest {
  advancedSearch?: {
    fields: string[];
    keyword: string;
  };
  keyword?: string;
  advancedFilter?: AdvancedFilter;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string[];
  enterpriseCategoryIds?: string[];
  enterpriseSubActivityIds?: string[];
  locationFilter?: LocationFilter;
  scoreThreshold: number;
}

interface Coordinates {
  lat: number;
  lng: number;
}

interface LocationFilter {
  coordinates: Coordinates;
  radiusInKilometers: number;
}
